import React from 'react';
import CountUp from 'react-countup';
import style from './InformationList.module.css';
import { TEXTS } from '../../../texts';



const InformationList = ({ inView }) => {

  return (
    <ul className={style.information_list}>
      <li className={style.information_item}>
        <h2 className={style.information_item_subtitle}>${inView && <CountUp duration={2} end={100} />}M+</h2>
        <p className={style.information_item_text}>{TEXTS.aboutRevenue}</p>
      </li>
      <li className={style.information_item}>
        <h2 className={style.information_item_subtitle}>{inView && <CountUp duration={2} end={100} />}%</h2>
        <p className={style.information_item_text}>{TEXTS.aboutCustomers}</p>
      </li>
      <li className={style.information_item}>
        <h2 className={style.information_item_subtitle}>{inView && <CountUp duration={2} end={100} />}+</h2>
        <p className={style.information_item_text}>{TEXTS.aboutCountries}</p>
      </li>
      <li className={style.information_item}>
        <h2 className={style.information_item_subtitle}>{inView && <CountUp duration={2} end={7} />}</h2>
        <p className={style.information_item_text}>{TEXTS.aboutYearsInBusiness}</p>
      </li>
    </ul>
  )
}

export default InformationList;